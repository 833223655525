<template>
  <div id="schd">
    <div>
      <div class="outerTopSec">
        <b-container class="topSec">
          <b-row>
            <b-col>
              <b-row
                class="topSecrow title"
              >
                <b-col>
                  <h3>
                    Schedule
                  </h3>
                </b-col>
              </b-row>
            </b-col>
            <b-col>
              <b-row>
                <b-col class="col-md-12 col-12">
                  <div class="scheduleLogos"> 
                    <b-img
                      class="Tlogo"
                      :src="'https://assets.asiantour.com/asian-tour/2020/05/titleist-logo-png.png'"
                    ></b-img>
                    <b-img
                      class="logo"
                      :src="config.VUE_APP_LOGO + '2020/05/FootJoy.png'"
                    ></b-img>
                  </div>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row class="topSecrow FilterRow">
            <b-col lg='4' md='12' cols='12' class="SeasonDropdown">
              <span class="FilterYear">
              <b-form-select v-model="year" class="schedule-dropdown" v-on:change="changeYear(year)">
                <b-form-select-option 
                  class="opt-arrow"
                  v-for="(value,
                  index) in tm_params.seasons.seasons_entry.slice().reverse()"
                  :key="index"
                  :value="value.code"
                >{{value.desc}}</b-form-select-option>
              </b-form-select>
              </span>
            </b-col>
            <b-col lg='8' md='12' cols='12' class="Searchbox">
              <span class="FilterYear">
                <div class="outerInput">
                  <input onfocus="this.placeholder = ''" onblur="this.placeholder = 'Find Tournament'" class="form-control Findatourn" type="text" v-model="search" placeholder=" Find Tournament" />
                  <div>
                    <img class="searchMag" src="https://assets.asiantour.com/asian-tour/2023/12/Icon_feather-search.png">
                  </div>
                  <!-- <font-awesome-icon :icon="['fa', 'magnifying-glass']" /> -->
                  <!-- <b-form-input class="Findatourn" id="filter-input" name="gsearch" v-model="filter" placeholder="Find a Tournament"></b-form-input> -->
                </div>
              </span>
            </b-col>
          </b-row>
        </b-container>
      </div>
    </div>
      <template v-if="this.data == null">
        <div class="NoTourns">The Tournament Schedule for {{this.year}} will be announced soon</div>
      </template>
      <template v-else>
        <ScheduleTable
          :fields="fields"
          :id="id"
          :data="data"
          :develop="develop"
          :filter="filter"
          :code="tm_params.code"
          :config="config"
          :months="months"
          :year="year"
          :search="search"
        />        
      </template>
  </div>
</template>

<script>
import ScheduleTable from "@/components/scheduleTable.vue";
import axios from "axios";
export default {
  name: "schedule",
  props: ["season", "develop", 'config', 'seasonsadt'],
  components: {
    ScheduleTable
  },
  data() {
    return {
      totalRows: 1,
      currentPage: 1,
      filter: null,
      filterOn: [],
      tm_params: [],
      data: [],
      search:'',
      id: [],
      fields: [
        { key: "dic_dates", label: "Date", class: "fontBold" },
        { key: "full_name", label: "Tournament", class: "tournBold tournName" },
        { key: "course", label: "Venue", class: "fontBold venueMob" },
        { key: "total_prize_fund", label: "Prize Fund", class: "fontBold d-none d-md-block" },
        { key: "winner_name", label: "Winner", class:"d-none d-md-block winnerName"},
        { key: "text", label: "", class:"d-none d-md-block winnerName"},
      ],
      months: [
        {
          value: 'Jan',
          full_name: 'January'
        },
        {
          value: 'Feb',
          full_name: 'February'
        },
        {
          value: 'Mar',
          full_name: 'March'
        },
        {
          value: 'Apr',
          full_name: 'April'
        },
        {
          value: 'May',
          full_name: 'May'
        },
        {
          value: 'Jun',
          full_name: 'June'
        },
        {
          value: 'Jul',
          full_name: 'July'
        },
        {
          value: 'Aug',
          full_name: 'August'
        },
        {
          value: 'Sep',
          full_name: 'September'
        },
        {
          value: 'Oct',
          full_name: 'October'
        },
        {
          value: 'Nov',
          full_name: 'November'
        },
        {
          value: 'Dec',
          full_name: 'December'
        },
      ],
      year: this.season,
      selected: this.season,
    };
  },
  methods: {
    seasonDes(id){
      var year = this.tm_params.seasons.seasons_entry.filter((seas) => seas.code === id)
      return year[0].desc
    },
    changeYear: function(year) {
      console.log('Changing Year')
      return (
        axios
          .get(
            process.env.VUE_APP_TIC_BASE +
              'apga/' +
              year +
              "/tmticx?randomadd=" +
              new Date().getTime()
          )
          .then(
            (response) =>
              (this.data = response.data.tournaments.tournaments_entry)
          )
      );
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    }
  },
  mounted() {
    setTimeout(() => {
    axios //add API Call
      .get(
        process.env.VUE_APP_TIC_BASE +
          this.develop +
          "/" +
          this.season +
          "/tmticx?randomadd=" +
          new Date().getTime()
      ) // Page number changed on each page based on JSON page id you are calling
      .then(response => {
        this.tm_params = response.data
          this.id = response.data.tm_params.season_code; 
          return axios.get(
            process.env.VUE_APP_TIC_BASE  +
              this.develop +
              "/" +
              this.season +
              "/tmticx?randomadd=" +
              new Date().getTime()
          );
      })
      .then(response => {
        this.data = response.data.tournaments.tournaments_entry;
      });
    }, 1000)
  },
};
</script>

<style scoped>
::v-deep input.form-control.Findatourn > div:focus::placeholder {
  color: transparent;
}
::v-deep div#placeholder:focus {
  color:#fff;
}
.Tlogo {
  width: 120px;
  margin-right: 20px;
}
img.searchMag {
  float: right;
  margin-top: -28px;
  margin-right: 16px;
}
.custom-select {
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0;
  border: 0px solid #c7c7cc;
  height: 43px;
  font-size: 16px;
  width: 100%;
  color: #7E7E7E !important;
  background: #fff url(https://assets.asiantour.com/asian-tour/2023/12/Icon_awesome-chevron-down.png) right 0.75rem center/12px 8px no-repeat;
}
.Searchbox {
  padding-right: 0;
}
.SeasonDropdown {
  padding-left: 0;
}
::v-deep .dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529!important;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
::v-deep div#dropdown-1 > button.dropdown-toggle::after {
  margin-top: 8px;
  float: right;
}
::v-deep button#dropdown-1__BV_toggle_ {
  text-align: left;
}
.NoTourns {
  font-weight: bold;
  color: #133f7a;
  text-align: center;
}
[type="search"] {
  padding-left: 10px;
}
.outerInput {
  width: 100%;
}
input.Findatourn::placeholder {
  color: #7E7E7E !important;
  padding-left: 0px;
}
.Findatourn {
  border-radius: 0;
  border: 0px solid #c7c7cc;
  height: 43px;
  font-size: 16px;
  width: 100%;
}
.yearDropdown {
  margin-top: 1px !important;
  display: block;
  padding-bottom: 0.5em;
  width: 100%;
}
.scheduleP {
  font-weight: bold;
  color: #133f7a;
  font-size: 14px;
  width: 200px;
}
.FilterRow {
  margin-top: 1em;
  border-radius: 0px;
  width: 100%;
  margin-left: 0px;
  padding-bottom: 0.5%;
}
span.FilterYear {
  display: flex;
}
.scheduleLogos {
  float: right;
}
::v-deep td.tournBold {
  color: #337ab7;
  /* font-weight: bold; */
  font-weight: 500;
  height: 70px;
}
::v-deep td.fontBold {
  /* font-weight: bold; */
  font-weight: 500;
  color: #7E7E7E;
}
::v-deep tbody {
  font-size: 12px !important;
}
#schd {
  /* background-color: #f1f1f1; */
  padding-bottom: 5%;
}
::v-deep button {
  color: #7E7E7E !important;
  background-color: #fff!important;
  width: 100%;
  border-radius: 0px !important;
  border: 0px solid #c7c7cc;
  height: 43px;
  font-size: 16px;
}
::v-deep button:hover {
  color: #7E7E7E !important;
  background-color: transparent;
  width: 100%;
  border-radius: 0px !important;
  border: 1px solid #c7c7cc;
  height: 43px;
  font-size: 16px;
}
::v-deep th {
  color: white;
  background-color: #133f7b;
  padding: 9px;
  font-size: 13px;
}
::v-deep tr:nth-child(odd) {
  background-color: white;
  /* border-bottom: 1px solid #eceeee; */
}
::v-deep tr:nth-child(even) {
  background-color: #fff;
  /* border-bottom: 1px solid #eceeee; */
}
.outerTopSec {
  background-color: #f5f5f5;  
}
.topSec {
  padding-top: 4%;
  padding-bottom: 4%;
  padding-left: 25px;
  padding-right: 25px;
  background-color: #f5f5f5;
}

.title {
  color: #0b3f7e;
  background: transparent!important;
  float: left;
}
.developGreen {
  color: #76c3bd !important;
}
::v-deep .developGreen > .btn {
  background-color: #76c3bd !important;
  border-color: #76c3bd !important;
  color: white!important;
}
.topSecrow {
  padding-top: 2%;
}
.logo {
  width: 120px;
}
p {
  margin-top: 4px;
}
@media (min-width: 768px) {
  .m-md-2 {
    margin: 0 !important;
  }
}

@media only screen and (max-width: 480px) {
  ::v-deep .btn {
    font-size: 10pt;
    width: 195px;
  }
}
@media only screen and (max-width: 768px) {
  ::v-deep th.venueMob {
    border-top-right-radius: 0px;
  }
  .FilterRow {
    display: block;
  }
  .scheduleP {
    width: 100%;
  }
  span.FilterYear {
    display: revert;
  }
  .topSecrow {
    padding-bottom: 3%;
  }
}
@media (min-width: 768px){
  ::v-deep .d-md-block {
    display: revert!important;
  }
}

@media only screen and (max-width: 990px) {
  .scheduleLogos {
    float: right;
    display: inline-flex;
    display: none;
  }
  ::v-deep .SeasonDropdown {
    padding-left: 0px!important;
    padding-right: 0px;
    margin-bottom: 15px;
  }
  .Searchbox {
    padding-right: 0px;
    padding-left: 0px;
  }
  .topSec {
    padding-top: 4%;
    padding-bottom: 4%;
    padding-left: 15px;
    padding-right: 15px;
  }
  .logo {
    width: 100%;
    height: 50px;
  }
}
</style>
